"use client";

import React from "react";

import DropboxIcon from "./DropboxIcon";
import GoogleDriveIcon from "./GoogleDriveIcon";
import { Brands } from "@palette.tools/utils";
import { LinkIcon, LucideProps } from "lucide-react";


export const brandIcons: Record<Brands, (props?: React.SVGProps<SVGSVGElement>) => React.ReactNode> = {
  [Brands.dropbox]: props => <DropboxIcon {...props} />,
  [Brands.googledrive]: props => <GoogleDriveIcon {...props} />,
}


export function getBrandIcon(brand: Brands | null | undefined, props?: React.SVGProps<SVGSVGElement>): React.ReactNode {

  if (brand === null || brand === undefined) return <LinkIcon {...props as LucideProps} />;

  const icon = brandIcons[brand](props);

  return icon || <LinkIcon {...props as LucideProps} />;

}