

// Returns a date of format: 01/01/2021, 12:00:00 AM
export const formatFullTimestamp = (timestamp: number) => {

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit"
  };

  return (new Date(timestamp)).toLocaleDateString('en-US', options);

}
