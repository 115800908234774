import React from "react";


export default function DropboxIcon(props: React.SVGProps<SVGSVGElement>) {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 25"
    fill="none"
    {...props}
  >
      <path fill="#F7F5F2" d="M7.7.32.48 4.913 7.7 9.506l7.223-4.593 7.223 4.593 7.222-4.593L22.146.32l-7.222 4.593L7.701.32ZM7.7 18.692.48 14.1 7.7 9.506l7.223 4.593-7.223 4.593ZM14.924 14.1l7.223-4.594 7.222 4.593-7.223 4.593-7.222-4.593ZM14.924 24.816l-7.223-4.593 7.223-4.593 7.223 4.593-7.223 4.593Z"/>
  </svg>
}