import React from "react";


export default function OpenInNewWindowIcon(props: React.SVGProps<SVGSVGElement>) {
  return <svg
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.7995 13.8H13.0664C12.9869 13.8425 12.896 13.8666 12.7995 13.8666H9.06615C9.04777 13.8666 9.03281 13.8517 9.03281 13.8333C9.03281 13.8149 9.04777 13.8 9.06615 13.8H12.7995ZM13.3661 13.3C13.3661 13.3965 13.342 13.4873 13.2995 13.5669V13.3V3.69997V3.43306C13.342 3.51259 13.3661 3.60346 13.3661 3.69997V13.3ZM13.0664 3.19997H12.7995H3.19948H2.93257C3.0121 3.15742 3.10297 3.1333 3.19948 3.1333H12.7995C12.896 3.1333 12.9869 3.15742 13.0664 3.19997ZM2.63281 3.69997C2.63281 3.60346 2.65693 3.51259 2.69948 3.43306V3.69997V7.4333C2.69948 7.45171 2.68455 7.46663 2.66615 7.46663C2.64774 7.46663 2.63281 7.45171 2.63281 7.4333V3.69997Z" fill="#FAFAFA" stroke="#F4F4F4"/>
    <path d="M5.86719 7.43311H9.06719V10.6331" stroke="#F4F4F4" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.66797 13.8331L9.06797 7.43311" stroke="#F4F4F4" strokeLinecap="round"/>
  </svg>
}