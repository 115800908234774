import React from "react";

export default function PauseIcon(props: React.SVGProps<SVGSVGElement>) {
  return <svg
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="3.33333" height="11.3333" rx="1" fill="white"/>
    <rect x="6.66699" width="3.33333" height="11.3333" rx="1" fill="white"/>
  </svg>
}
