


export enum Brands {
  googledrive = 0,
  dropbox = 1,
}


export const brandLabels = {
  [Brands.googledrive]: "Google Drive",
  [Brands.dropbox]: "Dropbox",
}


export function getBrandLabel(name: Brands | null | undefined): string | undefined {
  if (name === null || name === undefined) return undefined;
  
  return brandLabels[name];
}


export function getBrandFromLink(urlString: string): Brands | null {
  try {
    const url = new URL(urlString);
    const hostname = url.hostname;

    // Check Google Drive URLs
    if (hostname.endsWith("drive.google.com") || hostname.endsWith("docs.google.com")) {
      return Brands.googledrive;
    }

    // Check Dropbox URLs
    if (hostname.endsWith("www.dropbox.com") || hostname.endsWith("dropbox.com") || hostname.endsWith("dl.dropbox.com")) {
      return Brands.dropbox;
    }

    // If the URL doesn't match any of the patterns above, we don't know what it is
    return null;
  } catch (e) {
    return null;
  }
}