function hsvToRgb(h: number, s: number, v: number): [number, number, number] {
  const f = (n: number) => {
    const k = (n + h / 60) % 6;
    return v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  };
  return [Math.round(f(5) * 255), Math.round(f(3) * 255), Math.round(f(1) * 255)];
}


function hashString(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export function getColorById(id: string): string {
  const hash = hashString(id);
  const hue = Math.floor(hash % 360 / 20) * 20;
  const [r, g, b] = hsvToRgb(hue, 0.76, 0.99);
  return `rgb(${r}, ${g}, ${b})`;
}

export function getForegroundColorById(id: string): string {
  const hash = hashString(id);
  const hue = Math.floor(hash % 360 / 20) * 20;
  const [r, g, b] = hsvToRgb(hue, 0.97, 0.61);
  return `rgb(${r}, ${g}, ${b})`;
}