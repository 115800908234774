

class LRUCache<K, V> {
  private readonly _map = new Map<K, V>();
  private readonly _capacity: number;

  constructor(capacity: number) {
    this._capacity = capacity;
  }

  get(key: K): V | undefined {
    const value = this._map.get(key);
    if (value !== undefined) {
      this._map.delete(key);
      this._map.set(key, value);
    }
    return value;
  }

  set(key: K, value: V): void {
    if (this._map.size >= this._capacity) {
      const firstKey = this._map.keys().next().value;
      this._map.delete(firstKey);
    }
    this._map.set(key, value);
  }
}

const jsonCache = new LRUCache<object, string>(1000);

export function cachedStringify(obj: object | null | undefined): string {
  if (obj === null) {
    return "null";
  } else if (obj === undefined) {
    return "undefined";
  }

  const cachedResult = jsonCache.get(obj);
  if (cachedResult) {
    return cachedResult;
  }
  const str = JSON.stringify(obj);
  jsonCache.set(obj, str);
  return str;
}